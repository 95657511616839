import { debounce } from "../../js/Util";

const $headerBurgerBtn = $('.js-header-menu-burger');
const $headerMenu = $('.js-header-menu');
const $headerMenuClose = $('.js-header-menu-close');

$headerBurgerBtn.on('click', function (e) {
    $headerMenu.toggleClass('_active');

    e.preventDefault();
});

$headerMenuClose.on('click', function (e) {
    $headerMenu.removeClass('_active');

    e.preventDefault();
});
