/*
    Пример использования debounce:

    const debounceScroll = debounce(() => {
        do stuff...
    }, 100);

    App.W.on('scroll', () => {
        debounceScroll();
    });
*/

export function debounce(func, wait, immediate) {
    let timeout;

    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/*
    Пример использования throttle:

    function throttleScroll() {
        do stuff...
    }

    App.W.on('scroll', throttle(throttleScroll, 100));
*/

export function throttle(callable, wait) {
    let id;

    function call(context, list) {
        requestAnimationFrame(function () {
            callable.apply(context, list);
            id = 0;
        });
    }

    return function () {
        if (id) return;
        id = setTimeout(call, wait, this, arguments);
    };
}

/*
    Получение GET-параметров из строки
*/

export function getUrlVars() {
    let hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");
    let vars = [];
    let hash;

    for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

export function getUrlVar(name) {
    return getUrlVars()[name];
}

/*
    Скролл страницы вверх независимо от истории браузера
*/

export function scrollTopForce() {
    if ("scrollRestoration" in history) {
        history.scrollRestoration = "manual";
    }
}

/*
    Разбиение чисел на разряды (1000000 => 1 000 000)
*/

export function numberDivide(string) {
    if (string) {
        return string.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
    } else {
        return string;
    }
}

/*
    Склонение числительных
    Пример: declOfNum(count, ['комната', 'комнаты', 'комнат']);
*/

export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
    ];
}

