window.App = {
    W: $(window),
    D: $(document),
    H: $('html'),
    B: $('body'),
    screenL: 1440,
    screenXL: 1280,
    screenM: 1024,
    screenS: 767,
    screenXS: 480,
    ie: false,
    edge: false,
    firefox: false,
    mainpage: false
};


// Классы страницы
{
    if (App.H.hasClass('mobile')) {
        App.mobile = true;
    } else if (App.H.hasClass('safari') && 'ontouchstart' in window) {
        App.mobile = true;
        App.H.removeClass('osx').addClass('mobile ios');
    } else {
        App.H.addClass('desktop');
    }

    if (App.H.hasClass('ie')) {
        App.ie = true;
    }

    if (App.H.hasClass('edge')) {
        App.edge = true;
    }

    if (App.H.hasClass('firefox')) {
        App.firefox = true;
    }

    if (App.H.hasClass('page-main')) {
        App.mainpage = true;
    }
}
