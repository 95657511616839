import { debounce } from "./Util";

// Конец скролла
const debounceScroll = debounce(() => {
    App.W.trigger("scrolled");
}, 100);

App.W.on("scroll", () => {
    debounceScroll();
});

// Конец ресайза
const debounceResize = debounce(() => {
    App.W.trigger("resized");
}, 200);

App.W.on("resize", () => {
    debounceResize();
});

// Колесо мыши
if (App.firefox) {
    App.W.on("DOMMouseScroll", (e) => {
        if (e.originalEvent.detail > 0) {
            App.W.trigger("wheeldown");
        } else {
            App.W.trigger("wheelup");
        }
    });
} else {
    App.W.on("mousewheel", (e) => {
        if (e.originalEvent.wheelDelta < 0) {
            App.W.trigger("wheeldown");
        } else {
            App.W.trigger("wheelup");
        }
    });
}

// Пользователь покидает вкладку браузера или возвращается

function handleVisibilityChange() {
    if (document.hidden) {
        App.W.trigger("tableave");
    } else {
        App.W.trigger("tabenter");
    }
}

document.addEventListener("visibilitychange", handleVisibilityChange, false);

