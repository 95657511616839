export default class Validate {
    constructor(form) {
        this.form = $(form);
        this.inputs = this.form.find("input, select, textarea");
        this.required = this.form.find("[data-required]");
        this.email = this.form.find("[data-email]");
        this.minlength = this.form.find("[data-minlength]");
        this.fileformat = this.form.find("[data-fileformat]");
        this.recaptcha = this.form.find(".js-recaptcha");
        this.submitBtn = this.form.find("[data-submit]");
        this.messages = window.validateMessages || {
            required: "Не заполнено поле",
            minlength: "Не менее %min% символов",
            email: "Неправильный формат",
            fileformat: "Недопустимый тип файла",
            filesize: "Файл должен быть до %max% МБ",
        };

        /**
            Так как могут быть поля обязательные,
            но ещё не проверенные, приходится вводить
            для них дополнительный класс
        */
        this.form
            .find("[data-required]")
            .not(":checkbox")
            .addClass("_notvalidated");
        // для обязательных чекбоксов только те, что не отмечены по умолчанию
        this.form
            .find(":checkbox[data-required]")
            .not(":checked")
            .addClass("_notvalidated");

        this.handleChange();
    }

    handleChange() {
        const eventName = App.ie ? "change blur" : "change";

        this.inputs.on(eventName, (e) => {
            const $input = $(e.target);

            // Запускаем проверки
            if ($input.is(":checkbox[data-required]")) {
                this.validateRequiredCheckbox($input);
            } else if ($input.is("[data-required]")) {
                this.validateRequired($input);
            }

            if ($input.is("[type=email]") || $input.is("[data-email]")) {
                this.validateEmail($input);
            }

            if ($input.is("[data-minlength]")) {
                this.validateMinLength($input);
            }

            if ($input.is("[data-fileformat]")) {
                this.validateFileFormat($input);
            }

            /*if ( $input.is('[data-filesize]') ) {
                this.validateFileSize($input);
            }*/

            /**
                Теперь поле либо валидно, либо у него
                есть класс _error, и ложной разблокировки
                кнопки сабмита не будет
            */
            $input.removeClass("_notvalidated");

            // Считаем ошибки
            this.calculateErrors();
        });
    }

    validateField($input) {
        // Запускаем проверки
        if ($input.is(":checkbox[data-required]")) {
            this.validateRequiredCheckbox($input);
        } else if ($input.is("[data-required]")) {
            this.validateRequired($input);
        }

        if ($input.is("[type=email]") || $input.is("[data-email]")) {
            this.validateEmail($input);
        }

        if ($input.is("[data-minlength]")) {
            this.validateMinLength($input);
        }

        if ($input.is("[data-fileformat]")) {
            this.validateFileFormat($input);
        }

        /*if ( $input.is('[data-filesize]') ) {
            this.validateFileSize($input);
        }*/

        /**
            Теперь поле либо валидно, либо у него
            есть класс _error, и ложной разблокировки
            кнопки сабмита не будет
        */
        $input.removeClass("_notvalidated");
    }

    validateRequired($input) {
        // Проверяем обязательные поля

        const $label = $input.siblings("[data-label]");
        const val = $input.val().replace(/\s/g, "");

        /**
            Убираем или подставляем класс _error,
            а также текст об ошибке
        */

        if (val.length > 0) {
            $input.removeClass("_error");
            $label.text("");
        } else {
            $input.addClass("_error");
            $label.text(this.messages.required);
        }
    }

    validateRequiredCheckbox($input) {
        // Проверяем обязательные чекбоксы
        const $label = $input.siblings("[data-label]");

        if ($input.is(":checked")) {
            $input.removeClass("_error");
            $label.text("");
        } else {
            $input.addClass("_error");
            $label.text(this.messages.required);
        }
    }

    validateMinLength($input) {
        // Проверяем минимальное количество символов

        const $label = $input.siblings("[data-label]");
        const min = $input.data("minlength");
        const val = $.trim($input.val());

        /**
            Убираем или подставляем класс _error,
            а также текст об ошибке
        */

        if (val.length >= min) {
            // Достаточно символов

            $input.removeClass("_error");
            $label.text("");
        } else if (val.length > 0) {
            // Недостаточно символов

            const message = this.messages.minlength.replace("%min%", min);

            $input.addClass("_error");
            $label.text(message);
        } else if (val.length == 0 && $input.is(":not([data-required])")) {
            // Поле пустое и необязательное

            /**
                Если оно обязательное, в этом случае за него
                будет отвечать validateRequired()
            */

            $input.removeClass("_error");
            $label.text("");
        }
    }

    validateEmail($input) {
        // Проверяем email

        const $label = $input.siblings("[data-label]");
        const val = $input.val().replace(/\s/g, "");

        /**
            Если поле пустое, то не валидируем его,
            оставляем эту работу для validateRequired()
        */

        if (!val.length) return;

        /**
            Убираем или подставляем класс _error,
            а также текст об ошибке
        */

        if (
            /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i.test(
                val
            )
        ) {
            $input.removeClass("_error");
            $label.text("");
        } else {
            $input.addClass("_error");
            $label.text(this.messages.email);
        }
    }

    validateFileFormat($input) {
        // Проверяем расширение файла

        const $label = $input.siblings("[data-label]");
        const accept = $input.attr("accept").split(",");
        const val = $input.val();

        /**
            Если поле пустое, то не валидируем его,
            оставляем эту работу для validateRequired()
        */

        if (!val.length && $input.is("[data-required]")) {
            return;
        } else if (!val.length) {
            $input.removeClass("_error");
            $label.text("");
            return;
        }

        /**
            Убираем или подставляем класс _error,
            а также текст об ошибке
        */

        let ext = "." + val.split(".").pop();
        ext = ext.toLowerCase();

        if ($.inArray(ext, accept) > -1) {
            $input.removeClass("_error");
            $label.text("");

            /**
                Если формат файла ОК, после этого
                проверяем размер файла
            */
            if ($input.is("[data-filesize]")) {
                this.validateFileSize($input);
            }
        } else {
            $input.addClass("_error");
            $label.text(this.messages.fileformat);
        }
    }

    validateFileSize($input) {
        // Проверяем размер загруженного файла

        const $label = $input.siblings("[data-label]");
        const max = +$input.data("filesize");

        if ($input[0].files.length) {
            const size = $input[0].files[0].size / 1024 / 1024;

            if (size > max) {
                const message = this.messages.filesize.replace("%max%", max);
                $input.addClass("_error");
                $label.text(message);
            }
        }
    }

    // Проверить все ошибки формы
    validateAllFields() {
        this.inputs.each((index, el) => {
            let $currInput = $(el);

            this.validateField($currInput);
        });

        /* Если каптча не пройдена, то показываем ошибку */
        if (this.recaptcha.hasClass('_notvalidated')) {
            this.recaptcha.addClass('_error');
        }

        return this.returnErrorsCount() === 0;
    }

    returnErrorsCount() {
        return this.form.find("._error, ._notvalidated").length;
    }

    calculateErrors() {
        // Подводим итоги проверок

        const errors = this.form.find("._error, ._notvalidated").length;

        /**
            Считаем ошибки в форме,
            и блокируем или разблокируем
            кнопку сабмита
        */

        if (errors > 0) {
            this.submitBtn.attr("disabled", true);
        } else {
            this.submitBtn.removeAttr("disabled");
        }
    }
}
