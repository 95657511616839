
const $accordeonItems = $('.js-accordeon-item');

if ($accordeonItems.length) {
    $('.js-accordeon-title').on('click', function (index, el) {
        const $currEl = $(this).closest('.js-accordeon-item');

        $currEl.toggleClass('_open');
        $currEl.find('.js-accordeon-body').stop().slideToggle();
    });
}
