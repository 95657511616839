const $searchForm = $('.js-search-form');
const $searchField = $searchForm.find('.js-search-form-input');
const $searchResults = $('.js-search-form-results');

$searchField.on('input', function (e) {
    $searchForm.submit();

    e.preventDefault();
});

App.D.on('click', function (e) {
    if (!$searchResults.is(e.target) && !$searchResults.is(e.target) && $searchResults.has(e.target).length === 0) {
        $searchResults.empty();
        $searchResults.parent().removeClass('_active');
    }
});

$searchForm.on('submit', (e) => {
    e.preventDefault();

    if ($searchField.val().length >= 3) {
        $searchForm.ajaxSubmit({
            url: $searchForm.attr('action'),
            dataType: 'json',
            // data: this.$form.serialize(),
            type: $searchForm.attr('method') || 'GET',
            cache: false,
            success: (response) => {
                if (!response.success) {
                    // Ошибка на сервере
                    App.H
                        .removeClass('_form-success')
                        .addClass('_form-error _form-message');
                } else {
                    // Успех!
                    let results = '<p>Ничего не найдено. Попробуй изменить фразу для поиска</p>';

                    if ($(response.results).find('li').length !== 0) {
                        results = response.results;
                    }

                    $searchResults.parent().addClass('_active');
                    $searchResults.empty();
                    $searchResults.append(results);
                }
            },
            error: () => {
                // Ошибка отправки

                App.H
                    .removeClass('_form-success')
                    .addClass('_form-error _form-message');

                console.log('Ошибка отправки формы');
            }
        });
    }
});
