/*
    Блокировка скролла
*/

class LockScroll {
    constructor() {
        this.scroll = 0;
    }

    lock() {
        /**
         * Так как страница перепрыгивает вверх,
         * когда срабатывает overflow:hidden,
         * принудительно сколлим её до
         * запомненного состояния
         */
        this.scroll = App.W.scrollTop();

        App.B.addClass('_noscroll');
        $('html, body').scrollTop(this.scroll);
    }

    unlock() {
        App.B.removeClass('_noscroll');
        $('html, body').scrollTop(this.scroll);
    }
}

App.lockScroll = new LockScroll();
