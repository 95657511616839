/*
    Видео с ютуба на странице
*/

let $videoWrap = $('.js-video-item-wrap');
//let imgTemplate = '//img.youtube.com/vi/#/sddefault.jpg';
let imgTemplate = '//img.youtube.com/vi/#/maxresdefault.jpg';
const videoFrameHtml = `<iframe width="720" height="405" src="{url}" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>`;

if ($videoWrap.length) {
    generateVideoWrap($videoWrap);
}

export function generateVideoWrap($items) {
    $items.each((index, el) => {
        let $self = $(el);
        let previewPicUrl = $self.data('preview');

        $self.find('.youtube-video').append(`
            <div class="video-wrap"><div class="video-wrap__play js-video-item-wrap-play" style="background-image: url('${previewPicUrl}')"></div></div>
        `);
    });

    $('.js-video-item-wrap').find('.js-video-item-wrap-play').on('click', (e) => {
        let $self = $(e.currentTarget).closest('.js-video-item-wrap');
        let videoUrl = $self.data('video');
        $self.addClass('_play');

        ///let urlConnector = currFrameUrl.indexOf('?') > 0 ? '&' : '?';
        let template = videoFrameHtml.replace('{url}', videoUrl);

        /*let template = template.replace(/data-gtm-yt-inspected-[^"]*="[^"]*"/, ''); // удаляем для корректной аналитики
        template = template.replace(/ id="[^"]*"/, ''); // удаляем для корректной аналитики
        template = template.replace(/src="[^"]*"/, `src="${currFrameUrl + urlConnector}autoplay=1"`);
        template = template.replace(/allow="/, `allow="autoplay; `);*/

        $self.find('.youtube-video').append(`<div class="video-content">${template}</div>`);

        e.preventDefault();
    });
}

export function getYoutubePreview(currFrameUrl) {
    let _previewPics = currFrameUrl.split('/');
    let previewPicUrl;

    previewPicUrl = _previewPics[_previewPics.length - 1];
    return imgTemplate.replace('#', previewPicUrl.split('?')[0]);
}
