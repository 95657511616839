import { debounce } from "../../js/Util";
import { generateVideoWrap } from "../../js/YoutubeVideo";

const $typingAnimation = $('.js-typing-animation');
const $animationOnScroll = $('.js-animation-on-scroll');
const $personsAnimation = $('.js-persons-animation');
const $sliderNavigationItem = $('.js-slider-navigation-item');
const $mainSlider = $('.js-main-slider');
const $sliderDescriptionItems = $('.js-slider-description-item');
const $sliderDescriptionMobileItems = $('.js-offices-navigation-mobile-descriptions .js-slider-description-item');


App.W.on('load', function () {
    if ($('.js-vacancy-lottie-screen-2').length) {
        $('.vacancy-body-item').eq(0).append($('.js-vacancy-lottie-screen-2'));
    }

    if ($('.js-vacancy-lottie-screen-3').length) {
        if ($('.vacancy-body-item').length >= 3) {
            $('.vacancy-body-item').eq(2).append($('.js-vacancy-lottie-screen-3'));
        }
    }

    function loadLottie($wrap) {
        const animation = lottie.loadAnimation({
            container: $wrap[0], // контейнер для анимации
            renderer: 'svg', // тип рендерера (может быть 'svg', 'canvas' или 'html')
            loop: false, // зацикливание анимации
            autoplay: true, // автоматический запуск анимации
            path: $wrap.data('lottie'),
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });

        $wrap.addClass('_lottie-animated');
        lottie.resize();
    }

    function showTypingText($el) {
        const countSymbols = $el.find('span').length;
        let symbolsCounter = 0;
        let currentInterval = setInterval(function () {
            symbolsCounter++;

            if (symbolsCounter >= countSymbols) {
                clearInterval(currentInterval);
                $el.addClass('_animated');

                setTimeout(function () {
                    $el.find('.temp-animation-wrap').hide().remove();

                    if ($el.data('separate') && App.H.outerWidth() > App.screenL) {
                        separateText($el);
                    }

                    if ($el.is('.js-main-animate-title')) {
                        /*if (App.H.outerWidth() > App.screenL) {
                            setAnimateMainTitle($el);
                        }*/
                        setAnimateMainTitle($el);

                        if ($('.js-text-and-search').length) {
                            $('.js-text-and-search').addClass('_animated');
                        }
                    }
                }, 200);
            } else {
                let randomTimeout = Math.random() * (70 - 10) + 10;

                setTimeout(function () {
                    $el.find('._hidden').first().removeClass('_hidden');
                }, randomTimeout);
            }
        }, 30);
    }

    function separateText() {
        if ($('[data-separate]').length) {
            const $separatedItems = $('[data-separate]');

            $separatedItems.each(function (index, el) {
                let $currEl = $(el);
                let needIndex = 0;
                let wordArray = $currEl.text().split(' ');
                let startText;
                let finishText;

                if ($currEl.data('separate') === 'end') {
                    needIndex = wordArray.length - 1;
                    finishText = wordArray[needIndex];
                    wordArray.splice(needIndex, 1);
                    startText = wordArray.join(' ');
                }

                $currEl.empty();
                $currEl.append('<span></span><span></span>');
                $currEl.find('span').eq(0).html(startText);
                $currEl.find('span').eq(1).html(' ' + finishText);
            });
        }
    }

    function setAnimateMainTitle($element) {
        let spanArr = $element.find('span');
        let needMargin = 0;

        if (App.H.outerWidth() > App.screenL) {
            spanArr.each(function (index, el) {
                needMargin += $(el).outerWidth();
            });

            needMargin = $element.outerWidth() - needMargin; // ширина экрана минус ширина блока с поиском и минус отсупы родителя

            $element.find('span').last().css({
                transform: `translateX(${needMargin}px)`
            });

            setTimeout(function () {
                $element.find('span').last().addClass('_finish-animate');
                $element.addClass('_finish-animate');
            }, 1000);
        } else {
            separateText($element);
            $element.addClass('_finish-animate');
        }

    }

    const onScrollPage = debounce(() => {
        const scrollTop = $(window).scrollTop();

        if ($animationOnScroll.length) {
            $animationOnScroll.each(function (index, el) {
                let $el = $(el);

                //if (scrollTop > $el.offset().top - App.H.outerHeight() / 1.2) {
                if (scrollTop > $el.offset().top - App.W.outerHeight() / 1.2) {
                    if (!$el.is('._animated')) {
                        $el.addClass('_animated');
                        $el.attr('data-step1', scrollTop).data('step1', scrollTop);

                        if ($el.is('.js-lottie-wrap') && !$el.is('._lottie-animated')) {
                            loadLottie($el);
                        }
                    } else {
                        if ($el.data('animation-steps')!== undefined) {
                            if (scrollTop - $el.data('step1') >= $el.data('animation-steps')) {
                                $el.addClass('_animated2');
                            }
                        }
                    }

                    if ($el.is('.js-padding-title') && App.W.outerWidth() <= App.screenXS) {
                        const $lastSpan = $el.find('span').last();
                        const needMargin = $el.outerWidth() - $lastSpan.outerWidth();

                        $lastSpan.css({
                            transform: `translateX(${needMargin}px)`
                        });
                    }

                    if ($el.is('.js-our-principles-list') && App.W.outerWidth() > App.screenM) {
                        $el[0].scrollLeft = 200;
                    }
                }
            });

            $typingAnimation.each(function (index, el) {
                let $el = $(el);

                //if (scrollTop > $el.offset().top - App.H.outerHeight() / 1.2) {
                if (scrollTop > $el.offset().top - App.W.outerHeight() / 1.2) {
                    /*let $el = $(el);*/

                    if (!$el.is('._animated')) {
                        $el.addClass('_visible');
                        showTypingText($el);
                    }
                }
            });
        }
    }, 100);

    if ($typingAnimation.length) {
        $typingAnimation.each(function (index, el) {
            let $el = $(el);
            let currContent = $el.text();
            let symbols = currContent.split('');

            $el.empty().append($("<span>").addClass('source-content').text(currContent));
            $el.append('<div class="temp-animation-wrap"></div>');
            $el.addClass('_typing-animation');

            $.each(symbols, function(i, v) {
                $el.find('.temp-animation-wrap').append($("<span>").addClass('_hidden').text(v));
            });
        });

        /*App.W.on('scroll', () => {
            onScrollPage();
        });

        //setGridItems();
        setTimeout(function () {
            onScrollPage();
        }, 500);*/
    }

    App.W.on('scroll', () => {
        onScrollPage();
    });

    //setGridItems();
    setTimeout(function () {
        onScrollPage();
    }, 500);

    if ($personsAnimation.length) {
        setTimeout(function () {
            $personsAnimation.addClass('_animate1');
        }, 300);

        setTimeout(function () {
            $personsAnimation.addClass('_animate2');
        }, 2500);

        setTimeout(function () {
            $personsAnimation.addClass('_animate3');
        }, 2800);

        loadLottie($personsAnimation.find('.js-animation-sketch .js-lottie-wrap'));
    }

    if ($mainSlider.length) {
        const $sliderActiveCircle = $('.js-slider-active-circle');






        /*** урать после доработки бэкв **/
        const tempVideoContent =
            `
                <div class="main-slider__slide swiper-slide">
                    <div class="video js-video-item-wrap" data-preview="/themes/winlinehr/assets/img/content/main/video1-preview.jpg" data-video="/themes/winlinehr/assets/video/party1_min.mp4">
                        <div class="youtube-video"></div>
                    </div>
                </div>
                <div class="main-slider__slide swiper-slide">
                    <div class="video js-video-item-wrap" data-preview="/themes/winlinehr/assets/img/content/main/video2-preview.jpg" data-video="/themes/winlinehr/assets/video/party2_min.mp4">
                        <div class="youtube-video"></div>
                    </div>
                </div>
            `;


        if ($('.our-history-slider').length) {
            $mainSlider.eq(0).find('.swiper-wrapper').prepend(tempVideoContent);
            generateVideoWrap($mainSlider.eq(0).find('.js-video-item-wrap'));
        }

        /*** урать после доработки бэкв **/












        $mainSlider.each(function (index, el) {
            let $currSlider = $(el);
            $currSlider.addClass(`js-main-slider-${(index + 1)}`);
            $currSlider.find('.js-main-slider-next').addClass(`js-main-slider-next-${(index + 1)}`);
            $currSlider.find('.js-main-slider-prev').addClass(`js-main-slider-prev-${(index + 1)}`);
            $currSlider.find('.js-main-slider-pagination').addClass(`js-main-slider-pagination-${(index + 1)}`);

            new Swiper(`.js-main-slider-${(index + 1)}`, {
                spaceBetween: 24,
                slidesPerView: 1,
                effect: 'fade',
                speed: 700,
                fadeEffect: {
                    crossFade: true
                },
                grabCursor: true,
                navigation: {
                    nextEl: `.js-main-slider-next-${(index + 1)}`,
                    prevEl: `.js-main-slider-prev-${(index + 1)}`,
                },
                pagination: {
                    el: `.js-main-slider-pagination-${(index + 1)}`,
                    type: 'bullets'
                },
                on: {
                    slideChangeTransitionEnd: function () {
                        stopAndRemoveVideoFrame();
                    }
                }
            });
        });

        $sliderNavigationItem.each(function (index, el) {
            $(el).data('index', (index + 1));
            $sliderDescriptionItems.eq(index).addClass(`js-slider-description-item-${(index + 1)}`);
            $sliderDescriptionMobileItems.eq(index).addClass(`js-slider-description-item-${(index + 1)}`);
        });

        $sliderNavigationItem.on('click', function (e) {
            let $currItem = $(this);
            let currIndex = $currItem.data('index');
            let needMargin = 0;

            $sliderNavigationItem.removeClass('_active');
            $currItem.addClass('_active');

            $mainSlider.removeClass('_active');
            $(`.js-main-slider-${(currIndex)}`).addClass('_active');

            $sliderDescriptionItems.removeClass('_active');
            $sliderDescriptionMobileItems.removeClass('_active');
            $(`.js-slider-description-item-${(currIndex)}`).addClass('_active');

            needMargin = currIndex === 1 ? 40 : $sliderNavigationItem.eq(0).outerHeight() * currIndex - 25;

            if (App.W.outerWidth() <= App.screenM) {
                needMargin = currIndex === 1 ? 24 : $currItem.eq(0).offset().left;

                $sliderActiveCircle.css({
                    'left': `${needMargin}px`
                });
            } else {
                $sliderActiveCircle.css({
                    'top': `${needMargin}px`
                });
            }

            stopAndRemoveVideoFrame();

            e.preventDefault();
        });

        $sliderNavigationItem.eq(0).trigger('click');
    }

    function stopAndRemoveVideoFrame() {
        const $videos = $('.js-video-item-wrap');

        $videos
            .filter('._play')
            .closest('.js-content-slider')
            .removeClass('_video-playing');

        $videos
            .filter('._play')
            .removeClass('_play')
            .find('.video-content').remove();
    }

    function horizontalFreeSlider() {
        const $horizontalFreeSliders = $('.js-horizontal-free-slider');

        $horizontalFreeSliders.each(function (index, el) {
            $(el).addClass(`js-horizontal-free-slider-${index}`);

            new Swiper(`.js-horizontal-free-slider-${index}`, {
                direction: 'horizontal',
                grabCursor: true,
                freeMode: true,
                slidesPerView: "auto",
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
                //mousewheel: true
                mousewheel: { forceToAxis: true }
            });
        });
    }

    function setParentWidth($items) {
        let currOuterWidth = 0;

        $items.each(function (index, el) {
            // проверяю в первой строке находится элемент или нет. По идее надо сравнивать с 0,
            // но в сафари почему-то некоторые элементы бажут и выдают значения не 0, а около того, поэтому сраниванием с 50, как с некой погрешностью
            if ($(el).offset().top - $(el).parent().offset().top < 50) {
                currOuterWidth += $(el).outerWidth(true, true);
            }
        });

        $items.eq(0).parent().css({
            'min-width': `${currOuterWidth}px`
        });
    }

    function runningLineGen() {
        const $runningLines = $('.js-running-line');

        $runningLines.each(function (index, el) {
            $(el).addClass(`js-running-line-${index}`);

            new Swiper(`.js-running-line-${index}`, {
                direction: 'horizontal',
                //slidesPerView: $(el).data('count') ? $(el).data('count') : 9,
                noSwiping: false,
                speed: $(el).data('speed') ? parseInt($(el).data('speed')) : 2000,
                loop: true,
                breakpoints: {
                    320: {
                        slidesPerView: 5
                    },
                    640: {
                        slidesPerView: 7
                    },
                    1024: {
                        slidesPerView: 8
                    },
                    1600: {
                        slidesPerView: 11
                    }
                },
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                    reverseDirection: !$(el).is('.reverse-direction'),
                },
            });
        });
    }


    if ($('.js-horizontal-free-slider').length) {
        // хак для правильного рассчета ширины контейнера для сафари
        if (App.H.hasClass('osx') || App.H.hasClass('ios')) {
            if ($('.js-our-history-item').length) {
                setParentWidth($('.js-our-history-item'));
            }
        }

        horizontalFreeSlider();
    }

    if ($('.js-running-line').length) {
        runningLineGen();
    }

    $('.js-anchor-list a, .js-anchor').on('click', function () {
        let anchor = $(this).attr('href');

        $('html, body').animate({scrollTop: $(anchor).offset().top - 130}, 500);
    });


    const $footerRunningLine = $('.js-running-footer-line');
    if ($footerRunningLine.length) {
        new Swiper(`.js-running-footer-line`, {
            direction: 'horizontal',
            slidesPerView: 2.5,
            noSwiping: false,
            speed: 5000,
            loop: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
                reverseDirection: false,
            }
        });
    }
});


