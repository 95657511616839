import Validate from './Validate.js';


class Form {
    constructor(form) {
        this.$form = $(form);
        this.$btn = this.$form.find('.js-submit');
        this.recaptcha;
        this.validateObj = "";

        this.checkFilled();
        this.popLabels();
        this.validate();
        this.initRecaptcha();

        //this.spamCheck();
        this.bindSubmit();
        this.bindClose();
        this.disableAutoFill();
        this.disableIEautocomplete();
        this.fileUpload();
        this.filterPhone();
    }


    checkFilled() {
        this.$form.find('.js-input').on('input', function (e) {
            const $currItem = $(this);

            if (e.target.value.length) {
                $currItem.parents('.js-input-wrap').addClass('_filled');
            } else {
                $currItem.parents('.js-input-wrap').removeClass('_filled');
            }

            // Если файл
            if ($currItem.attr('type') === 'file') {
                const $parentField = $currItem.closest('.js-input-wrap').parent();
                const $resumeLink = $('#resume-link');

                if (this.value.length === 0) {
                    $parentField.removeClass('_file-attached');
                    $resumeLink.val('');
                    $currItem.siblings('.js-file-text').html('');
                } else {
                    $currItem.siblings('.js-file-text').html(this.value);
                    $parentField.addClass('_file-attached');
                    $resumeLink.val('file-attached');
                    $resumeLink.siblings('.form__error').html('');
                }
            }

            if ($currItem.is('#resume-link')) {
                const $parentField = $currItem.closest('.js-input-wrap').parent();

                if ($currItem.val().length > 0) {
                    $parentField.addClass('_link-attached');
                } else {
                    $parentField.removeClass('_link-attached');
                }
            }
        });
    }


    // Валидация
    validate() {
        /*new Validate(this.$form);*/
        this.validateObj = new Validate(this.$form);
    }



    // Инициализируем рекапчу
    initRecaptcha() {
        const $container = this.$form.find(".js-recaptcha");
        const sitekey = $($container[0]).data("sitekey");

        grecaptcha.ready(function() {
            if (grecaptcha) {
                this.recaptcha = grecaptcha.render($container[0], {
                    sitekey: sitekey,
                    size: "invisible",
                    callback: () => {
                        this.sendForm();
                    },
                    "error-callback": () => {
                        // Ошибка на стороне рекапчи
                        this.$form.removeClass("_success").addClass("_error");

                        console.log("recaptcha error");
                    },
                });
            }
        });
    }



    // Событие отправки
    bindSubmit() {
        this.$form.on('submit', (e) => {
            e.preventDefault();

            // Блокируем кнопку сабмита
            this.$btn.attr('disabled', true);

            // Запускаем проверку рекапчи
            grecaptcha.execute(this.recaptcha);

            if (!this.validateObj.validateAllFields()) {
                e.preventDefault();

                const $firstError = this.$form.find('._error').first();
                const errorBox = $firstError[0].getBoundingClientRect();
                const isFirstErrorNotVisible = errorBox.top >= window.innerHeight || errorBox.bottom <= 0;

                if (isFirstErrorNotVisible) {
                    $("html, body").animate({scrollTop: $firstError.offset().top - 150}, 500);
                }

                this.$btn.removeAttr("disabled");
            } else {
                this.sendForm();
            }
        });
    }



    // Отправка
    sendForm() {
        this.$form.ajaxSubmit({
            url: this.$form.attr('action'),
            dataType: 'json',
            // data: this.$form.serialize(),
            type: this.$form.attr('method') || 'GET',
            cache: false,
            success: (response) => {

                if (!response.success) {
                    // Ошибка на сервере
                    App.H
                        .removeClass('_form-success')
                        .addClass('_form-error _form-message');

                    // Разблокируем кнопку
                    this.$btn.removeAttr('disabled');
                } else {
                    // Успех!
                    this.resetForm();
                    this.$form.find('.js-input-wrap').removeClass('_filled');

                    App.H
                        .removeClass('_form-error')
                        .addClass('_form-success _form-message');
                }

                // Автоматически закрываем сообщения
                // setTimeout(() => {
                // this.$form.removeClass('_success _error');
                // }, 5000);
            },
            error: () => {
                // Ошибка отправки

                App.H
                    .removeClass('_form-success')
                    .addClass('_form-error _form-message');

                console.log('Ошибка отправки формы');

                // Разблокируем кнопку
                this.$btn.removeAttr('disabled');

                // Автоматически закрываем сообщения
                // setTimeout(() => {
                // this.$form.removeClass('_success _error');
                // }, 5000);
            }
        });
    }



    // Закрытие формы
    bindClose() {
        App.H
            .find('.js-form-ok')
            .on('click', function (e) {
                e.preventDefault();
                App.H.removeClass('_form-success _form-error _form-message');
            });
    }



    // Загрузка файла
    fileUpload() {
        this.$form
            .find('.js-upload')
            .on('change', function () {
                const $input = $(this);
                const $label = $input.closest('.input-wrap').find('.js-upload-label');
                const val = $input.val();
                let fileName;

                if (val.length) {
                    fileName = val.split('\\').pop() || '';
                    $label.text(fileName);
                    $input.addClass('_active');
                } else {
                    $label.text($label.data('text'));
                    $input.removeClass('_active');
                }
            });


        this.$form
            .find('.js-upload-clear')
            .on('click', function () {
                $(this).siblings('.js-upload').val('').change();
            });
    }



    // Для поля телефона допускаем только цифры, пробел и +-()
    filterPhone() {
        this.$form.find('[data-phone]').on('input', function () {
            const val = $(this).val().replace(/[^0-9\s()+-]/g, '');
            $(this).val(val);
        });
    }



    // Сброс формы
    resetForm() {
        this.$form[0].reset();

        this.$form
            .find('.js-upload')
            .val('')
            .change()
            .siblings('[data-label]')
            .text('');

        // this.$form.removeClass('_success _error');

        this.$form.find('.js-file-text').html('');
        this.$form.find('._file-attached').removeClass('_file-attached');

        this.$form
            .find('[data-required]')
            .not(':checkbox')
            .addClass('_notvalidated')
            .removeClass('_error');

        this.$form
            .find(':checkbox[data-required]')
            .not(':checked')
            .addClass('_notvalidated');

        this.$form
            .find('.js-submit')
            .prop('disabled', false);

        this.$form
            .find('.js-input-wrap')
            .removeClass('_focused');

        this.$form
            .find('.js-dropdown')
            .trigger('dropdown:reset');

        grecaptcha.reset(this.recaptcha);
    }



    // Подсказки для инпутов
    popLabels() {
        this.$form
            .find('input.js-input, textarea.js-input')
            .on('input blur', function () {
                const val = $.trim($(this).val());

                if (!val.length || val == '+7() --') {
                    $(this).closest('.js-input-wrap').removeClass('_focused');
                } else {
                    $(this).closest('.js-input-wrap').addClass('_focused');
                }
            });
    }



    // Отключаем автозаполнение полей в хроме
    disableAutoFill() {
        this.$form
            .find('[type="text"], [type="email"], [type="tel"], [type="password"]')
            .attr('autocomplete', 'new-password');
    }



    // Фикс autocomplete="off" для IE и Edge
    disableIEautocomplete() {
        if (App.ie || App.edge) {
            window.onbeforeunload = function () {
                $('form[autocomplete="off"]').each(function () {
                    this.reset();
                });
            };
        }
    }



    // Защита от спама (если нет рекапчи)
    spamCheck() {
        App.secondsOnPage = 0;

        // Проверка phantomJS
        let isBot = function () {
            var retval = false;

            if (window._phantom || window.callPhantom) retval = true;
            if (window.__phantomas) retval = true; //PhantomJS-based web perf metrics + monitoring tool
            if (window.Buffer) retval = true; //nodejs
            if (window.emit) retval = true; //couchjs
            if (window.spawn) retval = true;  //rhino
            if (window.webdriver) retval = true; //selenium
            if (window.domAutomation || window.domAutomationController) retval = true; //chromium based automation driver
            // if (window.outerWidth === 0 && window.outerHeight === 0) retval = true; //headless browser

            return retval;
        };

        if (isBot()) {
            return false;
        }


        // Считаем, сколько времени пользователь провёл на сайте, и отправляем это в форме
        let name = this.$form.data('name');
        this.$form.append(`<input type="hidden" name="${name}" class="js-input-seconds" value="0" />`);


        clearInterval(App.antispamTimeout);
        App.antispamTimeout = setInterval(() => {
            App.secondsOnPage++;
            this.$form.find('.js-input-seconds').val(App.secondsOnPage);
        }, 1000);
    }
}

if ($('.js-form').length) {
    App.B.on('grecaptcha:loaded', function () {
        $('.js-form').each(function () {
            new Form(this);
        });
    });
}

const $scrollToFormBtn = $('.js-scroll-to-form');
if ($scrollToFormBtn.length) {
    $scrollToFormBtn.on('click', function () {
        $('html, body').animate({scrollTop: $('[data-form="vacancy-form"]').offset().top - 130}, 500);
    });
}


const $clearFile = $('.js-clear-file');
if ($clearFile.length) {
    $clearFile.on('click', function () {
        $('._file-attached').removeClass('_file-attached');
        $('#resume-link').val('').trigger('input');
        $('#resume-file').val('').trigger('input');
    });
}
