import { debounce } from "../../js/Util";

const $vacanciesList = $('.js-vacancies-list');

$('.js-load-more-vacancies').on('click', function (e) {
    const $currItem = $(this);
    const $currCategoryList = $currItem.closest('.js-vacancies-category-wrap').find('.js-vacancies-category-list');
    const clickOffset = $currItem.data('offset');

    $.ajax({
        url: $vacanciesList.data('url'),
        method: $vacanciesList.data('type') || "get",
        data: `category_id=${$currItem.data('category-id')}&offset=${$currItem.data('offset')}`,
        success: function (data) {
            if (data.length) {
                let vacancyItem;

                for (let i = 0; i < data.length; i++) {
                    let currItem = data[i];
                    let itemDepartments = '';
                    let itemTypes = '';
                    let itemFormats = '';
                    vacancyItem = $vacancyListItemTemplate;

                    vacancyItem = vacancyItem.replace('{{url}}', `${currItem.url}`);
                    vacancyItem = vacancyItem.replace('{{title}}', `${currItem.name}`);

                    /* Департаменты */
                    for (let j = 0; j < currItem.departments.length; j++) {
                        itemDepartments += `<span class="b-btn b-btn-grey">${currItem.departments[j].name}</span>`;
                    }
                    vacancyItem = vacancyItem.replace('{{departments}}', itemDepartments);

                    /* График работы */
                    for (let j = 0; j < currItem.timefactors.length; j++) {
                        itemTypes += `<span class="b-btn b-btn-grey">${currItem.timefactors[j].name}</span>`;
                    }
                    vacancyItem = vacancyItem.replace('{{itemTypes}}', itemTypes);

                    /* Занятость */
                    for (let j = 0; j < currItem.spacefactors.length; j++) {
                        itemFormats += `<span class="b-btn b-btn-grey">${currItem.spacefactors[j].name}</span>`;
                    }
                    vacancyItem = vacancyItem.replace('{{itemFormats}}', itemFormats);

                    $currCategoryList.append(vacancyItem);

                    if (currItem.last_record) {
                        $currItem.hide();
                    }

                }

                $currItem.data('offset', clickOffset + 5);
            } else {
                console.log('error');
            }
        },
    });

    e.preventDefault();
});


const $vacancyListItemTemplate = `
    <a href="{{url}}" class="vacancies-category__list-item">
        <div class="vacancies-category__list-item-title">
            <p class="mini-p">{{title}}</p>
        </div>

        <div class="vacancies-category__list-item-chars">
            <div class="vacancies-category__list-item-department">
                {{departments}}
            </div>
            <div class="vacancies-category__list-item-type">
                {{itemTypes}}
            </div>
            <div class="vacancies-category__list-item-format">
                {{itemFormats}}
            </div>
        </div>
    </a>
`;
